export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN ?? '';

export const API_PREFIX = API_DOMAIN + (process.env.REACT_APP_API_PREFIX ?? '/client_api');

export const ApiRoutes = {
    GetSid: `${API_PREFIX}/get_sid`,
    SearchProfessions: `${API_PREFIX}/professions_from_prompt`,
    FindBusinesses: `${API_PREFIX}/find_businesses`,
    GetBusinessNumber: `${API_PREFIX}/get_business_number`,
    GetContactPhoto: `${API_PREFIX}/contact_photo`,
    GetPostalCodeFromLocation: `${API_PREFIX}/closest_postal_code`,
    GetPostalCodeFromPrompt: `${API_PREFIX}/postal_code_from_prompt`,
} as const;
