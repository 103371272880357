import axios from 'axios';
import { useCallback } from 'react';
import { ApiRoutes } from '../configs/api.config';
import { IFindBusinessResponse, IGetBusinessRequestData, IGetBusinessResponseData } from '../types/businesess.types';

export const useApi = () => {
    const searchProfessions = useCallback(
        (prompt: string) => axios.post<string[]>(ApiRoutes.SearchProfessions, { prompt }),
        []
    );

    const findBusinesses = useCallback(
        (selectedProfession: string, zipInput: string) =>
            axios.post<IFindBusinessResponse>(ApiRoutes.FindBusinesses, {
                business_occupation: selectedProfession,
                client_location: zipInput,
            }),
        []
    );

    const getSid = useCallback(() => axios.post(ApiRoutes.GetSid, {}), []);

    const getBusinessNumber = useCallback(
        (data: IGetBusinessRequestData) => axios.post<IGetBusinessResponseData>(ApiRoutes.GetBusinessNumber, data),
        []
    );

    const getPostalCodeFromLocation = useCallback(
        (data: { latitude: number; longitude: number }) =>
            axios.post<number>(ApiRoutes.GetPostalCodeFromLocation, data),
        []
    );

    const getPostalCodeFromPrompt = useCallback(
        (value: string) =>
            axios.post<{ place_name: string; postal_code: string }[]>(ApiRoutes.GetPostalCodeFromPrompt, {
                prompt: value,
            }),
        []
    );

    return {
        searchProfessions,
        findBusinesses,
        getSid,
        getBusinessNumber,
        getPostalCodeFromLocation,
        getPostalCodeFromPrompt,
    };
};
