import { debounce } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useNotifications } from '../../contexts/notifications.context';
import { getUserLocation } from '../../helpers/getUserLocation';
import { useApi } from '../../hooks/useApi';
import { texts } from '../../texts/texts';
import { IBusinessResponseItem, IGetBusinessRequestData } from '../../types/businesess.types';
import { USER_NUMBER_LOCALSTORAGE_KEY } from './main.config';

interface IBusinessMetadata {
    isActiveNumber?: boolean;
    virtualNumber?: string;
}

export const useMain = () => {
    const {
        searchProfessions,
        findBusinesses,
        getBusinessNumber,
        getSid,
        getPostalCodeFromLocation,
        getPostalCodeFromPrompt,
    } = useApi();
    const { addNotification } = useNotifications();

    const [zipInput, setZipInput] = useState('');
    const [selectedProfession, setSelectedProfession] = useState<string | null>(null);
    const [professions, setProfessions] = useState<string[]>([]);
    const [isBusinessLoading, setIsBusinessLoading] = useState(false);
    // Uncomment the line below to see the example of the response
    // const [businessesList, setBusinessesList] = useState<IBusinessResponseItem[] | null>([
    //     {
    //         search_id: '1',
    //         business: {
    //             business_id: '213',
    //             occupations: ['Test occupation'],
    //             contact_name: 'TEST',
    //             contact_photo_exist: false,
    //         },
    //         stats: {
    //             quality: 3,
    //             price: 2,
    //             experience: 2,
    //         },
    //     },
    // ]);
    const [businessesList, setBusinessesList] = useState<IBusinessResponseItem[] | null>(null);
    const [businessMetadata, setBusinessMetadata] = useState<Record<string, IBusinessMetadata>>({});

    const debouncedProfessionsSearch = useMemo(
        () =>
            debounce((prompt: string) => {
                searchProfessions(prompt)
                    .then((response) => {
                        setProfessions(response.data);
                        setSelectedProfession(null);
                    })
                    .catch((error) => {
                        console.error(error);

                        addNotification({
                            severity: 'error',
                            text: texts.mainPage.searchProfessionFailed,
                        });
                    });
            }, 300),
        []
    );

    const loadBusinesses = useCallback((zipInput: string, selectedProfession: string | null) => {
        if (selectedProfession && zipInput) {
            setIsBusinessLoading(true);

            findBusinesses(selectedProfession, zipInput)
                .then((response) => {
                    const updatedBusinessesWithSearchId = response.data.businesses.map((item) => ({
                        ...item,
                        search_id: response.data.search_id,
                    }));

                    setBusinessesList(updatedBusinessesWithSearchId);
                })
                .catch((error) => {
                    console.error(error);

                    addNotification({
                        severity: 'error',
                        text: texts.mainPage.findBusinessFailed,
                    });
                })
                .finally(() => setIsBusinessLoading(false));
        }
    }, []);

    const loadBusinessNumber = useCallback((data: IGetBusinessRequestData) => {
        getBusinessNumber(data)
            .then((response) => {
                setBusinessMetadata((currState) => ({
                    ...currState,
                    [data.business_id]: {
                        ...(currState[data.business_id] || {}),
                        virtualNumber: response.data.virtual_number,
                    },
                }));

                localStorage.setItem(USER_NUMBER_LOCALSTORAGE_KEY, data.client_phone_number);
            })
            .catch((error) => {
                console.error(error);

                addNotification({
                    severity: 'error',
                    text: texts.mainPage.getNumberFailed,
                });
            });
    }, []);

    const initUserSid = useCallback(() => {
        if (!document.cookie.split('; ').find((row) => row.startsWith('sid'))) {
            getSid()
                .then(() => {})
                .catch(console.error);
        }
    }, []);

    const handleCurrentLocationClick = useCallback(async () => {
        try {
            const position = await getUserLocation();

            getPostalCodeFromLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
                .then((response) => {
                    setZipInput(response.data.toString());
                })
                .catch((error) => {
                    console.error(error);

                    addNotification({
                        severity: 'error',
                        text: texts.mainPage.loadPostalCodeFailed,
                    });
                });
        } catch (errorMessage) {
            console.error(errorMessage);

            addNotification({
                severity: 'error',
                text: errorMessage as string,
            });
        }
    }, []);

    const loadPostalCodeSuggestions = useCallback(async (value: string) => {
        return getPostalCodeFromPrompt(value)
            .then((response) => response.data.map(({ postal_code }) => postal_code))
            .catch((error) => {
                console.error(error);

                addNotification({
                    severity: 'error',
                    text: texts.mainPage.loadPostalCodeSuggestionsFailed,
                });

                return [];
            });
    }, []);

    return {
        zipInput,
        businessMetadata,
        businessesList,
        isBusinessLoading,
        professions,
        selectedProfession,
        setProfessions,
        setSelectedProfession,
        setIsBusinessLoading,
        setBusinessMetadata,
        setBusinessesList,
        setZipInput,
        debouncedProfessionsSearch,
        loadBusinesses,
        loadBusinessNumber,
        loadPostalCodeSuggestions,
        initUserSid,
        handleCurrentLocationClick,
    };
};
