import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { RoutesConfig } from './configs/routes.config';
import NotFoundView from './views/notFound/notFound.view';
import MainView from './views/main/main.view';

export default function RoutesList() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainView />} />
                <Route path={RoutesConfig.NotFound} element={<NotFoundView />} />
                <Route path="*" element={<Navigate to={RoutesConfig.NotFound} />} />
            </Routes>
        </Router>
    );
}
