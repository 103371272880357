import CallIcon from '@mui/icons-material/Call';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { Avatar, Button, Chip, CircularProgress } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Autocomplete from '../../components/autocomplete/autocomplete.component';
import Input from '../../components/input/input.component';
import { ApiRoutes } from '../../configs/api.config';
import { texts } from '../../texts/texts';
import { IBusinessResponseItem } from '../../types/businesess.types';
import { USER_NUMBER_LOCALSTORAGE_KEY } from './main.config';
import css from './main.module.scss';
import { useMain } from './useMain';

import classNames from 'classnames';

const PHONE_DEFAULT_COUNTRY = process.env.REACT_APP_PHONE_COUNTRY || 'de';

export default function MainView() {
    const {
        zipInput,
        businessMetadata,
        businessesList,
        isBusinessLoading,
        selectedProfession,
        professions,
        setSelectedProfession,
        setBusinessMetadata,
        setZipInput,
        debouncedProfessionsSearch,
        loadBusinesses,
        loadBusinessNumber,
        loadPostalCodeSuggestions,
        initUserSid,
        handleCurrentLocationClick,
    } = useMain();

    const [searchInput, setSearchInput] = useState('');
    const [callInput, setCallInput] = useState('');

    useEffect(() => {
        if (searchInput) {
            debouncedProfessionsSearch(searchInput);
        }

        return () => {
            debouncedProfessionsSearch.clear();
        };
    }, [searchInput, debouncedProfessionsSearch]);

    useEffect(() => {
        loadBusinesses(zipInput, selectedProfession);
    }, [zipInput, selectedProfession]);

    useEffect(() => {
        initUserSid();

        const userNumber = localStorage.getItem(USER_NUMBER_LOCALSTORAGE_KEY);

        if (userNumber) {
            setCallInput(userNumber);
        }
    }, []);

    const handleSearchInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    }, []);

    const handleZipInputChange = useCallback((value: string | null) => {
        setZipInput(value || '');
    }, []);

    const handleCallInputChange = useCallback((phone: string) => {
        setCallInput(phone);
    }, []);

    const handleProfessionClick = useCallback((profession: string) => {
        setSelectedProfession(profession);
    }, []);

    const handleGetBusinessNumberClick = useCallback((businessId: string) => {
        setBusinessMetadata((currState) => ({
            ...currState,
            [businessId]: { ...(currState[businessId] || {}), isActiveNumber: true },
        }));
    }, []);

    const handleCallInputBtn = useCallback(
        (business: IBusinessResponseItem) => {
            if (!selectedProfession) {
                return;
            }

            loadBusinessNumber({
                business_id: business.business.business_id,
                client_phone_number: callInput,
                occupation: selectedProfession,
                search_id: business.search_id,
            });
        },
        [callInput, selectedProfession]
    );

    return (
        <div>
            <header className="flex justify-center py-4 bg-indigo-700">
                <h6
                    className="flex-none text-2xl font-semibold leading-6 text-white"
                    style={{
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.1rem',
                    }}
                >
                    {texts.mainPage.title}
                </h6>
            </header>
            <main className="pb-6 px-4 flex flex-col items-center">
                <h1 className="hidden md:block pt-12 text-xl text-center font-semibold leading-6">
                    {texts.mainPage.description[0]}{' '}
                    <span className="text-indigo-700">{texts.mainPage.description[1]}</span>,{' '}
                    {texts.mainPage.description[2]}{' '}
                    <span className="text-indigo-700">{texts.mainPage.description[3]}</span>{' '}
                    {texts.mainPage.description[4]}
                </h1>
                <div className="flex flex-col w-full md:w-auto md:flex-row items-center pt-6 md:pt-12 pb-6 gap-4">
                    <Input
                        className="flex-col items-center md:flex-row gap-2 w-full md:w-auto"
                        inputClassName="w-full md:w-auto max-w-[300px]"
                        id={'search'}
                        name={'search'}
                        label={texts.mainPage.occupationSearch}
                        value={searchInput}
                        onChange={handleSearchInputChange}
                    />
                    <Autocomplete
                        key={zipInput}
                        className="flex-col items-center md:flex-row gap-2 w-full md:w-auto"
                        inputClassName="w-full md:w-auto max-w-[300px]"
                        name={'zip'}
                        label={texts.mainPage.zipCode}
                        defaultValue={zipInput}
                        onChange={handleZipInputChange}
                        fetchFn={loadPostalCodeSuggestions}
                    />
                    <Button
                        sx={{
                            minWidth: 'initial',
                        }}
                        onClick={handleCurrentLocationClick}
                    >
                        <GpsFixedIcon />
                    </Button>
                </div>
                <div className="flex flex-col items-center w-full md:w-auto">
                    {!!professions.length && (
                        <ul className="flex justify-center items-center gap-2 flex-wrap pb-2 md:pb-4">
                            {professions.map((profession) => (
                                <li key={profession} className="flex justify-center">
                                    <Chip
                                        label={profession}
                                        variant={selectedProfession === profession ? 'filled' : 'outlined'}
                                        onClick={() => handleProfessionClick(profession)}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                    {isBusinessLoading && <CircularProgress className="mt-20" />}
                    {!isBusinessLoading && !businessesList?.length && (
                        <div className="pt-20 text-sm text-gray-500">{texts.mainPage.nothingFound}</div>
                    )}
                    {!isBusinessLoading && !!businessesList?.length && (
                        <div className="flex flex-col items-center w-full md:w-auto">
                            {!!businessesList?.length && (
                                <p className="pt-2 md:pt-6 text-sm text-gray-500">
                                    <span className="font-semibold">{businessesList?.length}</span>{' '}
                                    {texts.mainPage.businessesCount}
                                </p>
                            )}
                            <div className="flex flex-col items-center gap-6 pt-4 w-full md:w-auto">
                                {businessesList?.map((business) => (
                                    <div
                                        key={business.business.business_id}
                                        className="flex flex-col items-center md:items-start md:flex-row justify-between p-2 md:p-6 border border-gray-400 rounded-lg hover:shadow-md w-full md:w-auto"
                                    >
                                        <div className="flex w-full md:w-auto overflow-hidden">
                                            <Avatar
                                                alt={business.business.contact_name}
                                                sx={{
                                                    width: {
                                                        xs: 62,
                                                        md: 86,
                                                    },
                                                    height: {
                                                        xs: 62,
                                                        md: 86,
                                                    },
                                                }}
                                                className="mr-2 md:mr-8"
                                                src={
                                                    business.business.contact_photo_exist
                                                        ? `${ApiRoutes.GetContactPhoto}/${business.business.business_id}`
                                                        : undefined
                                                }
                                            />
                                            <div className="flex flex-col gap-2 pl-2 pr-2 w-full md:w-auto overflow-hidden">
                                                <p className="text-base font-semibold leading-6 text-indigo-700">
                                                    {business.business.contact_name}
                                                </p>
                                                <p className="text-base text-gray-400 text-ellipsis whitespace-nowrap overflow-hidden md:max-w-[340px] inline w-full md:w-auto">
                                                    {business.business.occupations.map((occupation, idx) => (
                                                        <span className="mr-1" key={occupation}>
                                                            {occupation}
                                                            {idx < business.business.occupations.length - 1 && ','}
                                                        </span>
                                                    ))}
                                                </p>
                                                <div className="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-6 self-start md:self-end">
                                                    <span className="flex items-center gap-1">
                                                        {texts.mainPage.quality}{' '}
                                                        <span className="font-semibold">{business.stats.quality}</span>
                                                    </span>
                                                    <span className="flex items-center gap-1">
                                                        {texts.mainPage.price}{' '}
                                                        <span className="font-semibold">{business.stats.price}</span>
                                                    </span>
                                                    <span className="flex items-center gap-1">
                                                        {texts.mainPage.experience}{' '}
                                                        <span className="font-semibold">
                                                            {business.stats.experience}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-center ml-0 mt-4 mb-2 md:mt-0 md:mb-0 md:ml-6 w-full md:w-[245px] h-[36px]">
                                            {businessMetadata[business.business.business_id]?.virtualNumber && (
                                                <p className="text-center">
                                                    {businessMetadata[business.business.business_id]?.virtualNumber}
                                                </p>
                                            )}
                                            {!businessMetadata[business.business.business_id]?.virtualNumber &&
                                                businessMetadata[business.business.business_id]?.isActiveNumber && (
                                                    <div>
                                                        <div className="relative">
                                                            <PhoneInput
                                                                className={classNames(css.main__phoneInputContainer)}
                                                                defaultCountry={PHONE_DEFAULT_COUNTRY}
                                                                value={callInput}
                                                                onChange={handleCallInputChange}
                                                            />
                                                            <CallIcon
                                                                onClick={() => handleCallInputBtn(business)}
                                                                className="absolute top-1/2 right-1 cursor-pointer -translate-y-1/2 text-indigo-700 hover:text-indigo-900"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            {!businessMetadata[business.business.business_id]?.virtualNumber &&
                                                !businessMetadata[business.business.business_id]?.isActiveNumber && (
                                                    <Button
                                                        onClick={() =>
                                                            handleGetBusinessNumberClick(business.business.business_id)
                                                        }
                                                        className={classNames(
                                                            css.main__phoneActionButton,
                                                            'w-[245px] h-min'
                                                        )}
                                                        color="primary"
                                                        variant="outlined"
                                                    >
                                                        <CallIcon />
                                                    </Button>
                                                )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
}
