import { Alert } from '@mui/material';
import { useEffect, useRef } from 'react';
import { INotification } from '../../contexts/notifications.context';

interface INotificationProps extends INotification {
    onClose: () => void;
}

export default function Notification({ uuid, severity, text, onClose }: INotificationProps) {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            onClose();
        }, 5000);

        return () => {
            onClose();
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return (
        <Alert className="mb-2.5" key={uuid} severity={severity} onClose={onClose}>
            {text}
        </Alert>
    );
}
