import { texts } from '../texts/texts';

export const getUserLocation = () => {
    return new Promise<GeolocationPosition>((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve(position);
                },
                (error) => {
                    reject(getUserLocationErrorMessage(error));
                }
            );
        } else {
            reject(texts.mainPage.geolocation.notSupportedInBrowser);
        }
    });
};

export function getUserLocationErrorMessage(error: GeolocationPositionError) {
    let message = '';

    switch (error.code) {
        case error.PERMISSION_DENIED:
            message = texts.mainPage.geolocation.userDenied;
            break;
        case error.POSITION_UNAVAILABLE:
            message = texts.mainPage.geolocation.locationUnavailable;
            break;
        case error.TIMEOUT:
            message = texts.mainPage.geolocation.requestTimedOut;
            break;
        default:
            message = texts.mainPage.geolocation.unknownError;
            break;
    }

    return message;
}
