import engLang from './texts.eng.json';
import gerLang from './texts.ger.json';

const LOCAL_STORAGE_LANG_KEY = 'LANG';

enum Languages {
    Ger = 'ger',
    En = 'en',
}

const localStorageValue = localStorage.getItem(LOCAL_STORAGE_LANG_KEY);
const envValue = process.env.REACT_APP_LANG;

const language = localStorageValue || envValue || Languages.En;

export const texts = language === Languages.En ? engLang : gerLang;
