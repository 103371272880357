import { createTheme, ThemeProvider } from '@mui/material';
import Notifications from './components/notifications/notifications.component';
import { NotificationsProvider } from './contexts/notifications.context';
import RoutesList from './RoutesList';

const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: 'rgb(67, 56, 202)',
            dark: '#002884',
            contrastText: '#fff',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <NotificationsProvider>
                <div className="App">
                    <Notifications />
                    <RoutesList />
                </div>
            </NotificationsProvider>
        </ThemeProvider>
    );
}

export default App;
