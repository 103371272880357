import { Collapse } from '@mui/material';
import { useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useNotifications } from '../../contexts/notifications.context';
import Notification from './notification.component';

export default function Notifications() {
    const { data, removeNotification } = useNotifications();

    const notifications = useMemo(() => (data.length > 2 ? data.slice(data.length - 2, data.length) : data), [data]);

    return (
        <div className="fixed top-6 left-2 sm:left-auto right-2 sm:right-8 z-20">
            <div className="relative">
                <TransitionGroup>
                    {notifications.map(({ text, severity, uuid }) => (
                        <Collapse key={uuid}>
                            <Notification
                                uuid={uuid}
                                severity={severity}
                                text={text}
                                onClose={() => removeNotification(uuid)}
                            />
                        </Collapse>
                    ))}
                </TransitionGroup>
            </div>
        </div>
    );
}
