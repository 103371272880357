import { useAutocomplete } from '@mui/base/useAutocomplete';
import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import { InputBaseProps } from '@mui/material/InputBase';
import classNames from 'classnames';
import { debounce } from 'lodash';
import * as React from 'react';
import { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import css from './autocomplete.module.scss';

interface IAutocompleteProps {
    label: string;
    name: string;
    defaultValue: string;
    error?: boolean;
    errorText?: string;
    className?: string;
    inputClassName?: string;
    onChange: (value: string | null) => void;
    onBlur?: InputBaseProps['onBlur'];
    fetchFn: (value: string) => Promise<string[]>;
}

export default function Autocomplete({
    label,
    defaultValue,
    name,
    error,
    errorText,
    className,
    onChange,
    onBlur,
    fetchFn,
}: IAutocompleteProps) {
    const [options, setOptions] = useState<string[]>([]);
    const debouncedSearch = useMemo(
        () =>
            debounce((value: string, fetchFn: (value: string) => Promise<string[]>) => {
                fetchFn(value)
                    .then((response) => {
                        setOptions(response);
                    })
                    .catch(() => {
                        setOptions([]);
                    });
            }, 300),
        []
    );

    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        setAnchorEl,
        value,
        focused,
    } = useAutocomplete({
        options: options,
        defaultValue,
        getOptionLabel: (option) => option,
        filterOptions: (options) => options,
        filterSelectedOptions: false,
        onInputChange: (_: React.SyntheticEvent, text: string, reason: AutocompleteInputChangeReason) => {
            if (reason === 'input') {
                debouncedSearch(text, fetchFn);
            }

            if (reason === 'reset') {
                setOptions([]);
            }
        },
    });

    useEffect(() => {
        onChange(value);
    }, [onChange, value]);

    const inputProps = getInputProps();

    const handleInputBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            inputProps.onBlur?.(event);
            onBlur?.(event);
        },
        [inputProps.onBlur, onBlur]
    );

    return (
        <div {...getRootProps()} className="flex items-center flex-col md:flex-row gap-2 w-full md:w-auto">
            <label
                {...getInputLabelProps()}
                className="pl-3.5 sm:pl-0 mb-2 mt-[7px] block text-base font-medium leading-6 text-gray-900"
            >
                {label}
            </label>
            <div
                ref={setAnchorEl}
                className={classNames('w-full md:w-auto max-w-[300px] relative', css.autocomplete__inputWrapper, {
                    [css.error]: error,
                    [css.focused]: focused,
                })}
            >
                <input {...inputProps} className={'w-full'} name={name} onBlur={handleInputBlur} />
                {error && <p className={css.autocomplete__error}>{errorText}</p>}
                {groupedOptions.length > 0 ? (
                    <ul className={css.autocomplete__listBox} {...getListboxProps()}>
                        {(groupedOptions as string[]).map((option, index) => {
                            return (
                                <li {...getOptionProps({ option, index })} key={option}>
                                    <span>{option}</span>
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </div>
        </div>
    );
}
