import { TextField } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import React from 'react';

interface IInputProps {
    id: string;
    name: string;
    label: string;
    value: string;
    className?: string;
    inputClassName?: string;
    error?: boolean;
    errorText?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: InputBaseProps['onBlur'];
}

export default function Input({
    id,
    name,
    label,
    className,
    inputClassName,
    value,
    error,
    errorText,
    onChange,
    onBlur,
}: IInputProps) {
    return (
        <div className={`flex items-center ${className}`}>
            <label htmlFor={id} className="text-base font-medium leading-6 text-gray-900 mr-4">
                {label}
            </label>
            <TextField
                className={inputClassName}
                id={id}
                variant="outlined"
                size="small"
                name={name}
                onBlur={onBlur}
                error={error}
                helperText={error ? errorText : ''}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}
