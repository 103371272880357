import { AlertColor } from '@mui/material/Alert/Alert';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { getUuid } from '../helpers/getUuid.helper';

export interface INotification {
    uuid: string;
    severity: AlertColor;
    text: string;
}

export interface INotificationsContext {
    data: INotification[];
    addNotification: (data: Omit<INotification, 'uuid'>) => void;
    removeNotification: (uuid: string) => void;
}

const NotificationsContext = createContext<INotificationsContext | null>(null);

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
    const [data, setData] = useState<INotification[]>([]);

    const addNotification = useCallback((notification: Omit<INotification, 'uuid'>) => {
        const uuid = getUuid();

        setData((currData) => [...currData, { ...notification, uuid }]);
    }, []);

    const removeNotification = useCallback((uuid: string) => {
        setData((currData) => currData.filter((i) => i.uuid !== uuid));
    }, []);

    return (
        <NotificationsContext.Provider
            value={{
                data,
                addNotification,
                removeNotification,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => {
    const context = useContext(NotificationsContext);

    if (!context) {
        throw new Error('useNotifications must be used within NotificationsProvider');
    }

    return context;
};
